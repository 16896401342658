import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions  } from '@angular/router';
import { HomeOneComponent } from './components/home-one/home-one.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';


const routes: Routes = [
    { path: '', component: HomeOneComponent },
    { path: 'privacy-policy', component: PrivacyPolicyComponent },
    { path: '**', redirectTo: '' }
];

const routerOptions: ExtraOptions = {
    scrollPositionRestoration: 'enabled', // Restores scroll to top on route change
    anchorScrolling: 'enabled', // Enables fragment navigation
    scrollOffset: [0, 64] // Adjusts scroll position to avoid overlapping headers, e.g., a fixed header
};

@NgModule({
    imports: [RouterModule.forRoot(routes, routerOptions)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
