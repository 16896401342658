import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-one',
  templateUrl: './home-one.component.html',
  styleUrls: ['./home-one.component.scss']
})
export class HomeOneComponent implements OnInit {

  imagenFabri: string = 'assets/img/iconos/Blanco/ICONOS-1.png';
  imagenConsul: string = 'assets/img/iconos/Negro/ICONOS-2.png';
  imagenMicro: string = 'assets/img/iconos/Negro/ICONOS-3.png';
  imagenArquit: string = 'assets/img/iconos/Negro/ICONOS-4.png';
  imagenDev: string = 'assets/img/iconos/Negro/ICONOS-5.png';
  imagenUxUi: string = 'assets/img/iconos/Negro/ICONOS-6.png';

  constructor() { }

  ngOnInit(): void {
  }

  cambiarImagen(name: string) {
    const imagenes = {
      'fabrica': ['Blanco', 'Negro', 'Negro', 'Negro', 'Negro', 'Negro'],
      'consultoria': ['Negro', 'Blanco', 'Negro', 'Negro', 'Negro', 'Negro'],
      'microservicio': ['Negro', 'Negro', 'Blanco', 'Negro', 'Negro', 'Negro'],
      'arquitectura': ['Negro', 'Negro', 'Negro', 'Blanco', 'Negro', 'Negro'],
      'dev': ['Negro', 'Negro', 'Negro', 'Negro', 'Blanco', 'Negro'],
      'uxui': ['Negro', 'Negro', 'Negro', 'Negro', 'Negro', 'Blanco']
    };

    const colores = imagenes[name];

    this.imagenFabri = `assets/img/iconos/${colores[0]}/ICONOS-1.png`;
    this.imagenConsul = `assets/img/iconos/${colores[1]}/ICONOS-2.png`;
    this.imagenMicro = `assets/img/iconos/${colores[2]}/ICONOS-3.png`;
    this.imagenArquit = `assets/img/iconos/${colores[3]}/ICONOS-4.png`;
    this.imagenDev = `assets/img/iconos/${colores[4]}/ICONOS-5.png`;
    this.imagenUxUi = `assets/img/iconos/${colores[5]}/ICONOS-6.png`;
  }

}
