<nav class="navbar navbar-expand-lg navbar-light sticky-top">
    <a class="navbar-brand" routerLink="/"><img src="assets/img/logo.png" alt="Logo" class="img-logo"></a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav">
            <li class="nav-item"><a class="nav-link" href="/#home" >Inicio</a></li>
            <li class="nav-item"><a class="nav-link" href="/#about" >Nosotros</a></li>
            <li class="nav-item"><a class="nav-link" href="/#services" >Servicios</a></li>
            <li class="nav-item"><a class="nav-link" href="/#team" >Equipo</a></li>
            <li class="nav-item"><a class="nav-link" href="/#contact" >Contáctenos</a></li>
            <li class="nav-item"><a class="nav-link" href="/#">Privacy Policy</a></li>
        </ul>
    </div>
  </nav>

<div class="container mt-5">
    <h1>Privacy Policy</h1>
    <p>Last updated: 13/11/2024</p>

    <p>Welcome to COODE! This Privacy Policy explains how we collect, use, and disclose information about you when you use our website and services. We respect your privacy and are committed to protecting it through our compliance with this policy.</p>

    <h2>1. Information We Collect</h2>

    <h3>Personal Information</h3>
    <p>We may collect personal information such as your name, email address, phone number, and any other information you voluntarily provide to us when you contact us, sign up for our services, or interact with our website.</p>

    <h3>Usage Data</h3>
    <p>We automatically collect certain information when you visit our website, including your IP address, browser type, operating system, referring URLs, pages viewed, and the dates and times of your visits. This information helps us understand how you use our site and improve our services.</p>

    <h3>Cookies and Tracking Technologies</h3>
    <p>We use cookies and similar tracking technologies to track the activity on our website and hold certain information. Cookies are files with a small amount of data, which may include an anonymous unique identifier. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our website.</p>

    <h2>2. How We Use Your Information</h2>
    <ul>
      <li>To provide, operate, and maintain our website</li>
      <li>To improve, personalize, and expand our website</li>
      <li>To understand and analyze how you use our website</li>
      <li>To communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the website, and for marketing and promotional purposes</li>
      <li>To process your transactions and manage your orders, if applicable</li>
      <li>To send you notifications, alerts, or promotional materials (you can opt-out anytime)</li>
      <li>To prevent fraudulent activity and improve security</li>
      <li>To comply with legal obligations and protect our rights</li>
    </ul>

    <h2>3. Sharing and Disclosure of Your Information</h2>
    <p>We may share your information in the following circumstances:</p>

    <ul>
      <li>With analytics and search engine providers that assist us in the improvement and optimization of our website.</li>
      <li>With affiliates, if applicable, in accordance with this policy.</li>
      <li>When required by law or in response to a subpoena, court order, or other legal processes.</li>
      <li>If we believe that disclosure is necessary to protect our rights or prevent fraud.</li>
      <li>In connection with, or during negotiations of, any merger, sale of assets, financing, or acquisition of all or a portion of our business by another company.</li>
    </ul>

    <h2>4. Cookies and Other Tracking Technologies</h2>
    <p>We use cookies and similar tracking technologies to collect and use personal information about you, including to serve interest-based advertising. You can learn more about cookies in our <a href="#">Cookie Policy</a>.</p>

    <h3>Types of Cookies We Use</h3>
    <ul>
      <li><strong>Essential Cookies:</strong> These cookies are necessary for the operation of our website. They enable you to navigate our website and use its features.</li>
      <li><strong>Performance Cookies:</strong> These cookies collect information about how you use our website, such as which pages you visit and if you experience any errors. These cookies do not collect information that could identify you and are used only to help us improve how our website works.</li>
      <li><strong>Functionality Cookies:</strong> These cookies allow us to remember choices you make (such as your language or region) and provide enhanced, more personal features.</li>
      <li><strong>Targeting/Advertising Cookies:</strong> These cookies are used to deliver ads more relevant to you and your interests.</li>
    </ul>

    <h3>Managing Cookies</h3>
    <p>You can manage your cookie preferences through your browser settings. Most browsers allow you to refuse or delete cookies. However, if you choose to disable cookies, some parts of our website may not function properly.</p>

    <h2>5. Your Rights and Choices</h2>
    <p>You may have certain rights regarding your personal information, subject to local data protection laws. These may include:</p>
    <ul>
      <li>The right to access and obtain a copy of your personal information</li>
      <li>The right to correct any inaccuracies in your personal information</li>
      <li>The right to request the deletion of your personal information</li>
      <li>The right to restrict or object to the processing of your personal information</li>
      <li>The right to data portability, where applicable</li>
    </ul>
    <p>If you wish to exercise any of these rights, please contact us at the email address provided below. We may need to verify your identity before processing your request.</p>

    <h2>6. Security of Your Information</h2>
    <p>We implement reasonable security measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, please be aware that no security measure is completely secure, and we cannot guarantee the absolute security of your information.</p>

    <h2>7. Retention of Your Information</h2>
    <p>We retain personal information only for as long as necessary to fulfill the purposes for which we collected it, including for the purposes of satisfying any legal, accounting, or reporting requirements.</p>

    <h2>8. Children's Privacy</h2>
    <p>Our website and services are not intended for children under the age of 18. We do not knowingly collect personal information from children under 18. If we become aware that we have collected personal information from children under the age of 18, we will take steps to delete it as soon as possible.</p>

    <h2>9. Changes to This Privacy Policy</h2>
    <p>We may update our Privacy Policy from time to time. Any changes will be posted on this page, and we will update the “Last updated” date at the top of this policy. We encourage you to review this policy periodically for any updates.</p>

    <h2>10. Contact Us</h2>
    <p>If you have any questions or concerns about this Privacy Policy, please contact us:</p>
    <ul>
      <li>Email: jcorremo@coode.tech, omar@coode.tech</li>
    </ul>

    <p>Thank you for reading our Privacy Policy.</p>
  </div>
